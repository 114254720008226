{
  "name": "lekto-admin",
  "version": "1.35.2",
  "buildVersion": "1",
  "scripts": {
    "ng": "ng",
    "start": "npm run pre-build && ng serve --host localhost.dev.lekto.com.br --port 4200 --disable-host-check",
    "start-https": "npm run pre-build && ng serve --ssl --host localhost.dev.lekto.com.br --port 4200 --disable-host-check",
    "start-stg": "npm run pre-build && ng serve --host localhost.stg.lekto.com.br --port 4200 --disable-host-check",
    "start-http": "http-server -p 4200 -a localhost.dev.lekto.com.br -c-1 dist",
    "build": "npm run pre-build && ng build --aot --named-chunks",
    "build-dev": "npm run pre-build && ng build --configuration dev --aot --build-optimizer --optimization --extract-licenses --subresource-integrity --output-hashing all",
    "build-prod": "npm run pre-build && ng build --configuration production --aot --build-optimizer --optimization --extract-licenses --subresource-integrity --output-hashing all",
    "build-production": "npm run pre-build && ng build --configuration production --aot --build-optimizer --optimization --extract-licenses --subresource-integrity --output-hashing all",
    "build-staging": "npm run pre-build && ng build --configuration staging --aot --build-optimizer --optimization --extract-licenses --subresource-integrity --output-hashing all",
    "build-production-services": "npm run pre-build && ng build --configuration production-services --aot --build-optimizer --optimization --extract-licenses --subresource-integrity --output-hashing all",
    "build-staging-services": "npm run pre-build && ng build --configuration staging-services --aot --build-optimizer --optimization --extract-licenses --subresource-integrity --output-hashing all",
    "build-online-development": "npm run pre-build && ng build --configuration online-development --aot --build-optimizer --optimization --extract-licenses --subresource-integrity --output-hashing all",
    "test": "ng test",
    "lint": "npx eslint .",
    "e2e": "ng e2e",
    "dk-build": "docker build . -t admin-fe:dev -f .ci/Dockerfile-dev",
    "dk-run-net": "docker run --rm --network main -p 4200:80 admin-fe:dev",
    "pre-build": "node pre-build.js",
    "format": "prettier --write \"src/**/*.{ts,js,json,css,scss,html,md}\""
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.3",
    "@angular/common": "^18.0.3",
    "@angular/compiler": "^18.0.3",
    "@angular/core": "^18.0.3",
    "@angular/forms": "^18.0.3",
    "@angular/localize": "^18.0.3",
    "@angular/platform-browser": "^18.0.3",
    "@angular/platform-browser-dynamic": "^18.0.3",
    "@angular/router": "^18.0.3",
    "@angular/service-worker": "^18.0.3",
    "@ant-design/icons-angular": "^18.0.0",
    "@apollo/client": "^3.10.5",
    "@auth0/angular-jwt": "^5.2.0",
    "@babel/code-frame": "^7.24.7",
    "@microsoft/signalr": "^8.0.0",
    "@ng-idle/core": "^14.0.0",
    "@ng-idle/keepalive": "^14.0.0",
    "@ngx-loading-bar/core": "^6.0.2",
    "@ngx-loading-bar/http-client": "^6.0.2",
    "@sentry/angular-ivy": "^7.114.0",
    "@sentry/tracing": "^7.114.0",
    "@types/crypto-js": "^4.2.2",
    "apexcharts": "^3.49.1",
    "apollo-angular": "^7.0.2",
    "apollo3-cache-persist": "^0.15.0",
    "crypto-hash": "^3.0.0",
    "crypto-js": "^4.2.0",
    "graphql": "^16.8.2",
    "jwt-decode": "^4.0.0",
    "ng-apexcharts": "^1.10.0",
    "ng-event-bus": "^6.0.0",
    "ng-zorro-antd": "^18.0.0",
    "ngx-clipboard": "^16.0.0",
    "ngx-cookie-service": "^18.0.0",
    "ngx-json-viewer": "^3.2.1",
    "ngx-mask": "^17.0.8",
    "ngx-photo-editor": "^0.4.4",
    "ngx-quill": "^26.0.2",
    "quill": "^2.0.2",
    "rxjs": "~7.8.1",
    "ts-md5": "^1.3.1",
    "tslib": "^2.6.2",
    "uuid": "^10.0.0",
    "zone.js": "^0.14.7"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "18.0.3",
    "@angular-eslint/builder": "^17.3.0",
    "@angular-eslint/eslint-plugin": "^17.3.0",
    "@angular-eslint/eslint-plugin-template": "^17.3.0",
    "@angular-eslint/schematics": "^17.3.0",
    "@angular-eslint/template-parser": "^17.3.0",
    "@angular/cli": "^18.0.3",
    "@angular/compiler-cli": "^18.0.3",
    "@types/jasmine": "~3.8.0",
    "@types/node": "^13.13.4",
    "@types/uuid": "^9.0.1",
    "@typescript-eslint/eslint-plugin": "^7.7.0",
    "@typescript-eslint/parser": "^7.7.0",
    "eslint": "^8.56.0",
    "jasmine-core": "~3.8.0",
    "karma": "~6.3.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.0.3",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "~1.7.0",
    "prettier": "^3.3.2",
    "typescript": "^5.4.5"
  }
}
